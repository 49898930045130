import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { ILocaleStringResourceState } from 'src/types/localization/locale-string-resource'
// utils
import axios, { API_ENDPOINTS } from 'src/utils/axios';

const initialState: ILocaleStringResourceState = {
    localeStringResource:[],
    localeStringResourceStatus:{
        loading:false,
        empty:false,
        error:null
    },
    localeStringResourceDelete: {
        loading:false,
        empty:false,
        error:null
    }
}

const slice = createSlice({
    name: 'localeStringResource',
    initialState,
    reducers:{
        getLocaleStringResourceStart(state) {
            state.localeStringResourceStatus.loading=true;
            state.localeStringResourceStatus.empty=false;
            state.localeStringResourceStatus.error = null;
        },
        getLocaleStringResourceFailure(state, action) {
            state.localeStringResourceStatus.loading=true;
            state.localeStringResourceStatus.empty=false;
            state.localeStringResourceStatus.error = action.payload;
        },
        getLocaleStringResourceSuccess(state, action) {
            const localeStringResource = action.payload;
            state.localeStringResource = localeStringResource;
            state.localeStringResourceStatus.loading = false;
            state.localeStringResourceStatus.empty = !localeStringResource.length;
            state.localeStringResourceStatus.error = null;
        },
        deleteLocaleStringResourceStart(state) {
            state.localeStringResourceDelete.loading=true;
            state.localeStringResourceDelete.empty=false;
            state.localeStringResourceDelete.error = null;
        },
        deleteLocaleStringResourceFailure(state, action) {
            state.localeStringResourceDelete.loading=true;
            state.localeStringResourceDelete.empty=false;
            state.localeStringResourceDelete.error = action.payload;
        },
        deleteLocaleStringResourceSuccess(state, action) {
            const localeStringResourceDelete = action.payload;
            state.localeStringResourceDelete = localeStringResourceDelete;
            state.localeStringResourceDelete.loading = false;
            state.localeStringResourceDelete.empty = action.payload;
            state.localeStringResourceDelete.error = null;
        }
    }
});

export default slice.reducer;

// Actions
export const {
    getLocaleStringResourceStart,
    getLocaleStringResourceFailure,
    getLocaleStringResourceSuccess,
    deleteLocaleStringResourceStart,
    deleteLocaleStringResourceFailure,
    deleteLocaleStringResourceSuccess
} = slice.actions;

export function getLocaleStringResource(data:any) {
        return async (dispatch: Dispatch) => {
            dispatch(slice.actions.getLocaleStringResourceStart());
            try {
                await axios.get(API_ENDPOINTS.ENQUIRYSETTINGS.BUYERTYPE.LIST).then((res:any)=> {
                    if(res.data.statusCode === 200)
                    dispatch(slice.actions.getLocaleStringResourceSuccess(res.data.data));
                else 
                     dispatch(slice.actions.getLocaleStringResourceSuccess([]));
                });
            }catch(error){
                dispatch(slice.actions.getLocaleStringResourceFailure(error));
            }
        }
}

export function deleteLocaleStringResource(id: any) {
    return  axios.delete(`${API_ENDPOINTS.ENQUIRYSETTINGS.BUYERTYPE.DELETE}/${id}`);
}

export function addUpdateLocaleStringResource(data: any) {
    return axios.post(API_ENDPOINTS.ENQUIRYSETTINGS.BUYERTYPE.ADDUPDATEBUYERTYPE,data);
}