import { Divider, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { Fragment, useEffect } from "react";
import DynamicFormTemplate from "../dynamic-form-template";


 
type Props = {
    index: any;
    item: any;
    currentModel: any;
    setValue: any;
    getValues: any;
    control: any;
    setNewModelSchema:any;
    inputTypeSize:any;
    onAttachmentClick?:any;
    onNotesClick?:any;
    onSubmitQueryClick?:any;
};

export default function DefaultDynamicForm({index, item, currentModel, setNewModelSchema, setValue, getValues, control,inputTypeSize,  
    onAttachmentClick, onNotesClick, onSubmitQueryClick}: Props) {
    const randomUUID:any  = crypto.randomUUID();
   return <Fragment key={`${randomUUID}1`}>
        {item.blockName !== "Default" && <Fragment key={`${randomUUID}2`} >
            <Typography variant="subtitle1" key={`${randomUUID}2`} style={{ width: "100%", paddingTop: "10px" }}> {item.blockName} </Typography>
            <Divider key={`${randomUUID}3`} sx={{ borderStyle: 'dashed', marginBottom: "10px" }} />
        </Fragment>}
        <Fragment key={`${randomUUID}4`}>
            <Stack spacing={2} display="grid" key={`${randomUUID}5`} gridTemplateColumns={{ xs: 'repeat(1, 1fr)', sm: `repeat(${item.flex}, 1fr)` }}>
                <Fragment key={`${randomUUID}6`}>
                    <DynamicFormTemplate onAttachmentClick={onAttachmentClick}  onNotesClick={onNotesClick} 
                     onSubmitQueryClick={onSubmitQueryClick} key={`${crypto.randomUUID()}`}  inputTypeSize={inputTypeSize} subFormName={item.subFormName ? `${item.subFormName}.` : ""}  formDetails={item.formDetail} currentData={currentModel}
                        indexValue={index}  setValue={setValue} getValues={getValues} control={control}  />
                </Fragment>
            </Stack>
        </Fragment>
    </Fragment>
}