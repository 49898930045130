// @mui
import { alpha, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';

// auth
import { useAuthContext } from 'src/auth/hooks';
// routes
// hooks
import { useResponsive } from 'src/hooks/use-responsive';
// theme
import { bgGradient } from 'src/theme/css';
// components
import Logo from 'src/components/logo';

// ----------------------------------------------------------------------

type Props = {
  title?: string;
  image?: string;
  children: React.ReactNode;
};

export default function DealerClassicLayout({ children, image, title }: Props) {
  const { method } = useAuthContext();

  const theme = useTheme();

  const upMd = useResponsive('up', 'md');

  const renderLogo = (
    <Logo
      sx={{
        zIndex: 9,
        // position: 'absolute',
        m: { xs: 2, sm: 4, md: 4 },
        margin: '16px 40px'
      }}
    />
  );

  const renderContent = (
    <Stack
      sx={{
        px: { xs: 2, sm: 4, md: 4 },
        py: { xs: 4, md: 4 },
      }}
    >
      {children}
    </Stack>
  );

  const renderSection = (
    <div
      style={{
        ...bgGradient({
          color: alpha(
            theme.palette.background.default,
            theme.palette.mode === 'light' ? 0.88 : 0.94
          ),
          imgUrl: '/assets/background/overlay_2.jpg',
        }),
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: 'auto'
      }}
    >
      {renderLogo}
      <Box
        component="img"
        alt="auth"
        src={image || '/assets/illustrations/illustration_dashboard.png'}
        sx={{ width: '100%', height: 'auto' }}
      />
    </div>
  );

  return (
    <Grid container>
      <Grid item xs={12} lg={7} display={{ xs: 'none', lg: 'flex' }}>
        {renderSection}
      </Grid>
      <Grid item xs={12} display={{ xs: 'flex', lg: 'none' }}>
        {renderLogo}
      </Grid>
      <Grid item xs={12} lg={5} display='grid' alignItems='center'>
        {renderContent}
      </Grid>
    </Grid >
  );
}
