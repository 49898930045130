import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { IMandalState } from 'src/types/mandal';
// utils
import axios, { API_ENDPOINTS } from 'src/utils/axios';


const initialState: IMandalState = {
    mandals:[],
    mandal:null,
    mandalsStatus:{
        loading:false,
        empty:false,
        error:null
    },
    mandalStatus:{
        loading:false,
        error:null
    },
    mandalDelete: {
        loading:false,
        empty:false,
        error:null
    }
}

const slice = createSlice({
    name: 'mandal',
    initialState,
    reducers:{
        getMandalsStart(state) {
            state.mandalsStatus.loading=true;
            state.mandalsStatus.empty=false;
            state.mandalsStatus.error = null;
        },
        getMandalsFailure(state, action) {
            state.mandalsStatus.loading=true;
            state.mandalsStatus.empty=false;
            state.mandalsStatus.error = action.payload;
        },
        getMandalsSuccess(state, action) {
            const mandals = action.payload;
            state.mandals = mandals;
            state.mandalsStatus.loading = false;
            state.mandalsStatus.empty = !mandals.length;
            state.mandalsStatus.error = null;
        }
        ,
        deleteMandalStart(state) {
            state.mandalDelete.loading=true;
            state.mandalDelete.empty=false;
            state.mandalDelete.error = null;
        },
        deleteMandalFailure(state, action) {
            state.mandalDelete.loading=true;
            state.mandalDelete.empty=false;
            state.mandalDelete.error = action.payload;
        },
        deleteMandalSuccess(state, action) {
            const mandalDelete = action.payload;
            state.mandalDelete = mandalDelete;
            state.mandalDelete.loading = false;
            state.mandalDelete.empty = action.payload;
            state.mandalDelete.error = null;
        }
    }
});

export default slice.reducer;

// Actions
export const {
    getMandalsStart,
    getMandalsFailure,
    getMandalsSuccess,
    deleteMandalStart,
    deleteMandalFailure,
    deleteMandalSuccess
} = slice.actions;

export function getMandals(data:any) {
        return async (dispatch: Dispatch) => {
            dispatch(slice.actions.getMandalsStart());
            try{
               // const response = await axios.post(API_ENDPOINTS.VEHICLECLASS.SEARCH,data);
                dispatch(slice.actions.getMandalsSuccess([
                    {id:1,mandalName:'ToHatch Backp',image:"",description:"ToHatch Backp Description",status:true},
                    {id:2,mandalName:'Sedan',image:"",description:"Sedan Description",status:false}
                ]
                    ));
            }catch(error){
                dispatch(slice.actions.getMandalsFailure(error));
            }
        }
}

export function deleteMandal(id: any) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.deleteMandalStart());
        try{
            const response = await axios.get(API_ENDPOINTS.BODYTYPE.DELETE+id);
            dispatch(slice.actions.deleteMandalSuccess(response.data));
        } catch(error){
            dispatch(slice.actions.deleteMandalFailure(error));
        }
    }
}
