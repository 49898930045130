import axios, { API_ENDPOINTS } from 'src/utils/axios';

export function resetPasswordSendLink(id: any,email:string) {
    return  axios.get(`${API_ENDPOINTS.auth.forgotPassword}/${email}/${id}`);
}


export function doLogin(data:any) {
    return  axios.post(API_ENDPOINTS.auth.login,data);
}


export function clientResetPasswordSendLink(id: any,email:string) {
    return  axios.get(`${API_ENDPOINTS.CLIENT.forgotPassword}/${email}/${id}`);
}

export function doClientLogin(data:any) {
    return  axios.post(API_ENDPOINTS.CLIENT.login,data);
}
