import { Helmet } from 'react-helmet-async';
import Header from 'src/layouts/main/header';
import HomeView from 'src/sections/home/view/home-view';
// sections

// ----------------------------------------------------------------------

export default function HomePage() {
  return (
    <>
      <Helmet>
        <title> Minimal: The starting point for your next project</title>
      </Helmet>
      <Header/>
      <HomeView />
    </>
  );
}
