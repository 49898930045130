import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { ICountryState } from 'src/types/country';
// utils
import axios, { API_ENDPOINTS } from 'src/utils/axios';


const initialState: ICountryState = {
    countries:[],
    country:null,
    countriesStatus:{
        loading:false,
        empty:false,
        error:null
    },
    countryStatus:{
        loading:false,
        error:null
    },
    countryDelete: {
        loading:false,
        empty:false,
        error:null
    }
}

const slice = createSlice({
    name: 'country',
    initialState,
    reducers:{
        getCountriesStart(state) {
            state.countriesStatus.loading=true;
            state.countriesStatus.empty=false;
            state.countriesStatus.error = null;
        },
        getCountriesFailure(state, action) {
            state.countriesStatus.loading=true;
            state.countriesStatus.empty=false;
            state.countriesStatus.error = action.payload;
        },
        getCountriesSuccess(state, action) {
            const countries = action.payload;
            state.countries = countries;
            state.countriesStatus.loading = false;
            state.countriesStatus.empty = !countries.length;
            state.countriesStatus.error = null;
        }
        ,
        deleteCountryStart(state) {
            state.countryDelete.loading=true;
            state.countryDelete.empty=false;
            state.countryDelete.error = null;
        },
        deleteCountryFailure(state, action) {
            state.countryDelete.loading=true;
            state.countryDelete.empty=false;
            state.countryDelete.error = action.payload;
        },
        deleteCountrySuccess(state, action) {
            const countryDelete = action.payload;
            state.countryDelete = countryDelete;
            state.countryDelete.loading = false;
            state.countryDelete.empty = action.payload;
            state.countryDelete.error = null;
        }
    }
});

export default slice.reducer;

// Actions
export const {
    getCountriesStart,
    getCountriesFailure,
    getCountriesSuccess,
    deleteCountryStart,
    deleteCountryFailure,
    deleteCountrySuccess
} = slice.actions;

export function getCountries(data:any) {
        return async (dispatch: Dispatch) => {
            dispatch(slice.actions.getCountriesStart());
            try{
               // const response = await axios.post(API_ENDPOINTS.VEHICLECLASS.SEARCH,data);
                dispatch(slice.actions.getCountriesSuccess([
                    {id:1,countryCode:"IND",countryName:'India',currency:"INR",description:"India Description",status:true},
                ]
                    ));
            }catch(error){
                dispatch(slice.actions.getCountriesFailure(error));
            }
        }
}

export function getAllCountries() {
           // const response = await axios.post(API_ENDPOINTS.VEHICLECLASS.SEARCH,data);
           return axios.get(API_ENDPOINTS.COUNTRY.ALL);
}

export function deleteCountry(id: any) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.deleteCountryStart());
        try{
            const response = await axios.get(API_ENDPOINTS.BODYTYPE.DELETE+id);
            dispatch(slice.actions.deleteCountrySuccess(response.data));
        } catch(error){
            dispatch(slice.actions.deleteCountryFailure(error));
        }
    }
}
