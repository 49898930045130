import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
// hooks
// routes
import { RouterLink } from 'src/routes/components';
import { useParams, useSearchParams } from 'src/routes/hook';
import { paths } from 'src/routes/paths';
// config
// auth
import { useAuthContext } from 'src/auth/hooks';
// components
import { useLocales } from 'src/locales';
import { clientResetPasswordSendLink } from 'src/redux/slices/auth';
import DynamicForByPageId from 'src/sections/masters/settings/dynamic-form/render-blocks/dynamic-form-by-page-id';
import { CONSTANTDEFAULTPAGES } from 'src/utils/contants/config-utils';
import { newFormGetFormBlockSetupTableId } from 'src/utils/dynamic.form.generation.common.code';

// ----------------------------------------------------------------------

type FormValuesProps = {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
};

export default function JwtClientForgotPasswordView() {
  const { register } = useAuthContext();
  const { t } = useLocales();
  const [errorMsg, setErrorMsg] = useState(false);
  const [forgotPasswordStatus, setForgotPasswordStatus] = useState(false);
  const [pageId, setPageId] = useState(CONSTANTDEFAULTPAGES.CLINETFORGOTPASSWORD);
  const [selectedRow, setSelectedRow] = useState({} as any);
  const [watchFields, setWatchFields] = useState([] as any);

  const searchParams = useSearchParams();
  const param = useParams();
  const clienName = param.name;
  const returnTo = searchParams.get('returnTo');
  useEffect(() => {
    newFormGetFormBlockSetupTableId(0, pageId, watchFields, setWatchFields, setSelectedRow);
  }, [])

  const defaultValues = useMemo(() => ({
    ...selectedRow
  }), [selectedRow]);
  
  const methods = useForm<FormValuesProps>({
  });

 
  const {
    handleSubmit, setValue, getValues,watch, control,
    formState: { isSubmitting }
} = methods;

  const onSubmitHandler = useCallback(
    (data: any) => {
      clientResetPasswordSendLink(CONSTANTDEFAULTPAGES.CLINETFORGOTPASSWORD,data.EmailAddress).then((res:any)=>{
        if (res.data.data > 0) {
          setForgotPasswordStatus(true);
        } else {
          setErrorMsg(true)
        }
      });
    }, []);

  const renderHead = (
    <Stack spacing={2} sx={{ mb: 2, position: 'relative' }}>
      <Typography variant="h4">{t("FORGOTPASSWORDFORMTITLE")}</Typography>
      <Stack direction="row" spacing={0.5}>
        <Typography variant="body2"> {t('AREADY_HAVE_ACCOUNT')} </Typography>
        <Link href={`${paths.auth.jwt.login}${clienName}`} component={RouterLink} variant="subtitle2">
        {t('SIGNIN')}
        </Link>
      </Stack>
    </Stack>
  );

  const renderTerms = (
    <Typography
      component="div"
      sx={{ color: 'text.secondary', mt: 2.5, typography: 'caption', textAlign: 'center' }}
    >
      {t('SIGNUP_IAGREE')}
      <Link underline="always" color="text.primary">
        {t('TERMS_SERVICE')}
      </Link>
       &nbsp;{t('AND')} &nbsp;
      <Link underline="always" color="text.primary">
        {t('PRIVACY_POLICY')}
      </Link>
      .
    </Typography>
  );

  const renderForm = (
    <Stack spacing={2.5}>
      {!!errorMsg && <Alert severity="error">{t("FORGOTPASSWORD_FAILED")}</Alert>}
        {!forgotPasswordStatus && selectedRow && selectedRow?.formBlockList &&  <DynamicForByPageId key={crypto.randomUUID()} pageId={pageId} 
         currentModel={defaultValues}   onSubmitHandler={onSubmitHandler}  children={<LoadingButton fullWidth  color="inherit" size="large" type="submit" variant="contained" loading={isSubmitting}>
         {t("REQUEST_PASSWORD")}
        </LoadingButton>} />}

     {forgotPasswordStatus &&   <>
       <Alert severity="success">{t("FORGOTPASSWORD_SUCCESS")}</Alert>
       <Alert severity="success"><div dangerouslySetInnerHTML= {{__html: t("FORGOTPASSWORD_SUCCESS_CONTENT") as any}}/></Alert>
      </> }
    </Stack>
  );

  return (
    <>
      {renderHead}
      <Typography variant="h4" style={{fontSize:'15px'}}>{t("FORGOTPASSWORD")}</Typography>
      {renderForm}
      {renderTerms}
    </>
  );
}
