import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { IVehicleStatusState } from 'src/types/vehicle-settings/vehicle.status';
// utils
import axios, { API_ENDPOINTS } from 'src/utils/axios';

const initialState: IVehicleStatusState = {
    vehicleStatus:[],
    vehicleStatusStatus:{
        loading:false,
        empty:false,
        error:null
    },
    vehicleStatusDelete: {
        loading:false,
        empty:false,
        error:null
    }
}

const slice = createSlice({
    name: 'vehicleStatus',
    initialState,
    reducers:{
        getVehicleStatusStart(state) {
            state.vehicleStatusStatus.loading=true;
            state.vehicleStatusStatus.empty=false;
            state.vehicleStatusStatus.error = null;
        },
        getVehicleStatusFailure(state, action) {
            state.vehicleStatusStatus.loading=true;
            state.vehicleStatusStatus.empty=false;
            state.vehicleStatusStatus.error = action.payload;
        },
        getVehicleStatusSuccess(state, action) {
            const vehicleStatus = action.payload;
            state.vehicleStatus = vehicleStatus;
            state.vehicleStatusStatus.loading = false;
            state.vehicleStatusStatus.empty = !vehicleStatus.length;
            state.vehicleStatusStatus.error = null;
        },
        deleteVehicleStatusStart(state) {
            state.vehicleStatusDelete.loading=true;
            state.vehicleStatusDelete.empty=false;
            state.vehicleStatusDelete.error = null;
        },
        deleteVehicleStatusFailure(state, action) {
            state.vehicleStatusDelete.loading=true;
            state.vehicleStatusDelete.empty=false;
            state.vehicleStatusDelete.error = action.payload;
        },
        deleteVehicleStatusSuccess(state, action) {
            const vehicleStatusDelete = action.payload;
            state.vehicleStatusDelete = vehicleStatusDelete;
            state.vehicleStatusDelete.loading = false;
            state.vehicleStatusDelete.empty = action.payload;
            state.vehicleStatusDelete.error = null;
        }
    }
});

export default slice.reducer;

// Actions
export const {
    getVehicleStatusStart,
    getVehicleStatusFailure,
    getVehicleStatusSuccess,
    deleteVehicleStatusStart,
    deleteVehicleStatusFailure,
    deleteVehicleStatusSuccess
} = slice.actions;

export function getVehicleStatus(data:any) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.getVehicleStatusStart());
        try {
            await axios.get(API_ENDPOINTS.VEHICLESETTINGS.VEHICLESTATUS.LIST).then((res:any)=> {
                if(res.data.statusCode === 200)
                dispatch(slice.actions.getVehicleStatusSuccess(res.data.data));
            else 
                 dispatch(slice.actions.getVehicleStatusFailure([]));
            });
        }catch(error){
            dispatch(slice.actions.getVehicleStatusFailure(error));
        }
    }
}

export function deleteVehicleStatus(id: any) {
return  axios.delete(`${API_ENDPOINTS.VEHICLESETTINGS.VEHICLESTATUS.DELETE}/${id}`);
}

export function addUpdateVehicleStatus(data: any) {
return axios.post(API_ENDPOINTS.VEHICLESETTINGS.VEHICLESTATUS.ADDUPDATEVEHICLESTATUS,data);
}