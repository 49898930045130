import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { ILanguageState } from 'src/types/localization/language'
// utils
import axios, { API_ENDPOINTS } from 'src/utils/axios';

const initialState: ILanguageState = {
    language:[],
    languageStatus:{
        loading:false,
        empty:false,
        error:null
    },
    languageDelete: {
        loading:false,
        empty:false,
        error:null
    }
}

const slice = createSlice({
    name: 'language',
    initialState,
    reducers:{
        getLanguageStart(state) {
            state.languageStatus.loading=true;
            state.languageStatus.empty=false;
            state.languageStatus.error = null;
        },
        getLanguageFailure(state, action) {
            state.languageStatus.loading=true;
            state.languageStatus.empty=false;
            state.languageStatus.error = action.payload;
        },
        getLanguageSuccess(state, action) {
            const language = action.payload;
            state.language = language;
            state.languageStatus.loading = false;
            state.languageStatus.empty = !language.length;
            state.languageStatus.error = null;
        },
        deleteLanguageStart(state) {
            state.languageDelete.loading=true;
            state.languageDelete.empty=false;
            state.languageDelete.error = null;
        },
        deleteLanguageFailure(state, action) {
            state.languageDelete.loading=true;
            state.languageDelete.empty=false;
            state.languageDelete.error = action.payload;
        },
        deleteLanguageSuccess(state, action) {
            const languageDelete = action.payload;
            state.languageDelete = languageDelete;
            state.languageDelete.loading = false;
            state.languageDelete.empty = action.payload;
            state.languageDelete.error = null;
        }
    }
});

export default slice.reducer;

// Actions
export const {
    getLanguageStart,
    getLanguageFailure,
    getLanguageSuccess,
    deleteLanguageStart,
    deleteLanguageFailure,
    deleteLanguageSuccess
} = slice.actions;

export function getLanguage() {
    return  axios.get(API_ENDPOINTS.CONFIGURATION.CONFIGURATIONDATA);
  }

export function deleteLanguage(id: any) {
    return  axios.delete(`${API_ENDPOINTS.ENQUIRYSETTINGS.BUYERTYPE.DELETE}/${id}`);
}

export function addUpdateLanguage(data: any) {
    return axios.post(API_ENDPOINTS.ENQUIRYSETTINGS.BUYERTYPE.ADDUPDATEBUYERTYPE,data);
}