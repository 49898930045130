import { Helmet } from 'react-helmet-async';
import DealerClassicLayout from 'src/layouts/auth/dealer';
// sections
import JwtClientRegisterView from 'src/sections/auth/jwt/client/jwt-client-register-view';

// ----------------------------------------------------------------------

export default function ClientRegisterPage() {
  return (
    <>
      <DealerClassicLayout>
        <>
          <Helmet>
            <title> SclinEDC: Register</title>
          </Helmet>

          <JwtClientRegisterView />
        </>
      </DealerClassicLayout>
    </>

  );
}
