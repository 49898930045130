import { yupResolver } from '@hookform/resolvers/yup';
import {  Stack} from '@mui/material';
import Alert from '@mui/material/Alert';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import FormProvider from 'src/components/hook-form';
import { formBlockListData } from 'src/utils/dynamic.form.generation.common.code';
import DefaultDynamicForm from './default-template';

export default function DynamicForByPageId({ inputTypeSize,  onSubmitHandler, currentModel,children }: any) {
    const [showError, setShowError] = useState(false as any);
    const [formBlockList, setFormBlockList] = useState([...currentModel.formBlockList] as any[]);
    const [tabFormBlockList, setTabFormBlockList] = useState([...currentModel.formBlockList] as any[]);
    const [newModelSchema, setNewModelSchema] = useState({} as any);
    const [newModelSchemaSubForm, setNewModelSchemaSubForm] = useState({} as any);
    const [watchFields, setWatchFields] = useState([...currentModel.watchFields] as any);

    const defaultValues = useMemo(() => ({
        ...currentModel
    }), [currentModel]);

    const methods = useForm<any>({
        resolver: yupResolver(newModelSchema),
        defaultValues
    });

    const {
        handleSubmit, setValue, getValues,watch, control,
        formState: { isSubmitting }
    } = methods;

    let values:any;
    if(watchFields?.length>0)
    values = watch(watchFields);

    const onSubmit = useCallback(
        async (data: any) => {  
            onSubmitHandler(data);
            console.log(data);
        }, []);

    // const bindData = useCallback((controlObj: any, shape: any, shapeDetail: any, subFormName: any) => {
    //     bindDataModel(controlObj, shape, shapeDetail, subFormName,currentModel,setValue);
    // }, [])

    useEffect(() => {
        const shape: any = {} as any;
        const shapeSubForm: any = {} as any;
        const formBlockData = formBlockList[0]
        if (formBlockData)
            formBlockData[formBlockData.subFormName] = currentModel[formBlockData.subFormName]

        setFormBlockList([...formBlockList])
        console.log(currentModel);
        formBlockListData(shape,shapeSubForm,formBlockList,currentModel,setValue,tabFormBlockList,setNewModelSchema,setNewModelSchemaSubForm);
    }, [])

    const renderBlock = useCallback((item: any, index: any) => {
        switch (item.blockType) {
            case "DEFAULT": {
                if (currentModel[item.subFormName])
                    item[item.subFormName] = currentModel[item.subFormName]

                const data ={index, item, currentModel, setNewModelSchema, setValue, getValues, control} as any
                return <DefaultDynamicForm key={`${crypto.randomUUID()}`} {...data} inputTypeSize={inputTypeSize}/>
            }
            default:
                return ""
        }
    }, []);

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            {showError && <Alert key={Math.random()} variant="outlined" severity="error" >
                Already exists with this record. Please try again
            </Alert>}
            <Stack spacing={1} sx={{ p: 1 }} key={Math.random()} display="grid" gridTemplateColumns={{ xs: 'repeat(1, 1fr)', sm: `repeat(1, 1fr)` }}>
                   {currentModel && currentModel.formBlockList && currentModel.formBlockList.map((item:any,index:any)=>(
                    <Fragment key={Math.random()}>
                        {renderBlock(item, crypto.randomUUID())}
                    </Fragment>
                    ))}
            </Stack>
            {children}
        </FormProvider>
    )
}