import { FormProvider as Form, UseFormReturn } from 'react-hook-form';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
  methods: UseFormReturn<any>;
  onSubmit?: VoidFunction;
  name?:string;
};

export default function FormProvider({ children, onSubmit, methods,name }: Props) {
  return (
    <Form {...methods}>
      <form onSubmit={onSubmit} name={name}>{children}</form>
    </Form>
  );
}
