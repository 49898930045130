import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { IOwnerState } from 'src/types/vehicle-settings/owners';
// utils
import axios, { API_ENDPOINTS } from 'src/utils/axios';

const initialState: IOwnerState = {
    owner:[],
    ownerStatus:{
        loading:false,
        empty:false,
        error:null
    },
    ownerDelete: {
        loading:false,
        empty:false,
        error:null
    }
}

const slice = createSlice({
    name: 'owner',
    initialState,
    reducers:{
        getOwnerStart(state) {
            state.ownerStatus.loading=true;
            state.ownerStatus.empty=false;
            state.ownerStatus.error = null;
        },
        getOwnerFailure(state, action) {
            state.ownerStatus.loading=true;
            state.ownerStatus.empty=false;
            state.ownerStatus.error = action.payload;
        },
        getOwnerSuccess(state, action) {
            const owner = action.payload;
            state.owner = owner;
            state.ownerStatus.loading = false;
            state.ownerStatus.empty = !owner.length;
            state.ownerStatus.error = null;
        },
        deleteOwnerStart(state) {
            state.ownerDelete.loading=true;
            state.ownerDelete.empty=false;
            state.ownerDelete.error = null;
        },
        deleteOwnerFailure(state, action) {
            state.ownerDelete.loading=true;
            state.ownerDelete.empty=false;
            state.ownerDelete.error = action.payload;
        },
        deleteOwnerSuccess(state, action) {
            const ownerDelete = action.payload;
            state.ownerDelete = ownerDelete;
            state.ownerDelete.loading = false;
            state.ownerDelete.empty = action.payload;
            state.ownerDelete.error = null;
        }
    }
});

export default slice.reducer;

// Actions
export const {
    getOwnerStart,
    getOwnerFailure,
    getOwnerSuccess,
    deleteOwnerStart,
    deleteOwnerFailure,
    deleteOwnerSuccess
} = slice.actions;
 

export function getVehicleOwners(data:any) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.getOwnerStart());
        try {
            await axios.get(API_ENDPOINTS.VEHICLESETTINGS.VEHICLEOWNER.LIST).then((res:any)=> {
                if(res.data.statusCode === 200)
                dispatch(slice.actions.getOwnerSuccess(res.data.data));
            else 
                 dispatch(slice.actions.getOwnerFailure([]));
            });
        }catch(error){
            dispatch(slice.actions.getOwnerFailure(error));
        }
    }
}

export function deleteVehicleOwner(id: any) {
return  axios.delete(`${API_ENDPOINTS.VEHICLESETTINGS.VEHICLEOWNER.DELETE}/${id}`);
}

export function addUpdateVehicleOwner(data: any) {
return axios.post(API_ENDPOINTS.VEHICLESETTINGS.VEHICLEOWNER.ADDUPDATEVEHICLEOWNER,data);
}