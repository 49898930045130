import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { IFormSetupState } from 'src/types/settings/form-setup';
// utils
import axios, { API_ENDPOINTS } from 'src/utils/axios';

const initialState: IFormSetupState = {
    formSetups:[],
    formSetupStatus:{
        loading:false,
        empty:false,
        error:null
    },
    formSetupDelete: {
        loading:false,
        empty:false,
        error:null
    }
}

const slice = createSlice({
    name: 'formSetup',
    initialState,
    reducers:{
        getFormSetupStart(state) {
            state.formSetupStatus.loading=true;
            state.formSetupStatus.empty=false;
            state.formSetupStatus.error = null;
        },
        getFormSetupFailure(state, action) {
            state.formSetupStatus.loading=true;
            state.formSetupStatus.empty=false;
            state.formSetupStatus.error = action.payload;
        },
        getFormSetupSuccess(state, action) {
            const formSetup = action.payload;
            state.formSetups = formSetup;
            state.formSetupStatus.loading = false;
            state.formSetupStatus.empty = !formSetup.length;
            state.formSetupStatus.error = null;
        },
        deleteformSetupStart(state) {
            state.formSetupDelete.loading=true;
            state.formSetupDelete.empty=false;
            state.formSetupDelete.error = null;
        },
        deleteformSetupFailure(state, action) {
            state.formSetupDelete.loading=true;
            state.formSetupDelete.empty=false;
            state.formSetupDelete.error = action.payload;
        },
        deleteformSetupSuccess(state, action) {
            const formSetupDelete = action.payload;
            state.formSetupDelete = formSetupDelete;
            state.formSetupDelete.loading = false;
            state.formSetupDelete.empty = action.payload;
            state.formSetupDelete.error = null;
        }
    }
});

export default slice.reducer;

// Actions
export const {
    getFormSetupStart,
    getFormSetupFailure,
    getFormSetupSuccess,
    deleteformSetupStart,
    deleteformSetupFailure,
    deleteformSetupSuccess
} = slice.actions;
 

export function getFormSetups(data:any) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.getFormSetupStart());
        try {
            await axios.get(API_ENDPOINTS.SETTINGS.PAGESETUP.GETPAGES).then((res:any)=> {
                if(res.data.statusCode === 200)
                dispatch(slice.actions.getFormSetupSuccess(res.data.data));
            else 
                 dispatch(slice.actions.getFormSetupFailure([]));
            });
        }catch(error){
            dispatch(slice.actions.getFormSetupFailure(error));
        }
    }
}

export function deleteFormSetup(id: any) {
return  axios.delete(`${API_ENDPOINTS.SETTINGS.PAGESETUP.DELETE}/${id}`);
}

export function addUpdatePageSetup(data: any) {
return axios.post(API_ENDPOINTS.SETTINGS.PAGESETUP.INSERTUPDATEPAGESETUP,data);
}