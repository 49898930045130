import axios from 'axios';
// config
import { HOST_API } from 'src/config-global';

// ----------------------------------------------------------------------
const axiosInstance = axios.create({ baseURL: HOST_API });

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;

export const API_ENDPOINTS = {
  chat: '/api/chat',
  kanban: '/api/kanban',
  calendar: '/api/calendar',
  CONFIGURATION:{
    CONFIGURATIONDATA: '/api/Auth/GetConfigurationData',
  },
  auth: {
    me: '/api/Auth/isValidToken',
    login: '/api/Auth/DoLogin',
    register: '/api/auth/register',
    forgotPassword: '/api/Auth/ResetPasswordSendLink',
  },
  mail: {
    list: '/api/mail/list',
    details: '/api/mail/details',
    labels: '/api/mail/labels',
  },
  LOOKUP: {
    LIST: '/api/Lookup/GetLookupByKey/',
    GETLOOKUPBYKEY: '/api/Lookup//GetLookupByKey/',
    GETLOOKUPDATABYID: '/api/Lookup/GetLookupDataById/',
  },
  VEHICLECLASS: {
    LIST: '/api/vehicleClass/list',
    DELETE: '/api/vehicleClass/delete/',
    SEARCH: '/api/vehicleClass/search',
  },
  BODYTYPE: {
    LIST: '/api/vehicleClass/list',
    DELETE: '/api/vehicleClass/delete/',
    SEARCH: '/api/vehicleClass/search',
  },
  CLIENT: {
    me: '/api/Client/isValidToken',
    login: '/api/Client/DoLogin',
    register: '/api/Client/register',
    forgotPassword: '/api/Client/ResetPasswordSendLink',
  },
  COUNTRY: {
    LIST: '/api/lookup/',
    DELETE: '/api/vehicleClass/delete/',
    SEARCH: '/api/vehicleClass/search',
    ALL: '/api/vehicleClass/search',
  },
  VEHICLEMODEL: {
    LIST: '/api/VehicleModel/GetVehicleModels',
    ADDUPDATEVEHICLEMODEL: '/api/VehicleModel/AddUpdateVehicleModel',
    DELETE: '/api/VehicleModel/DeleteVehicleModel'
  },
  PRODUCTSETTTINGS:{
    VEHICLECLASS: {
      LIST: '/api/VehicleClass/GetVehicleClasses',
      ADDUPDATEVEHICLECLASS : '/api/VehicleClass/AddUpdateVehicleClass',
      DELETE: '/api/VehicleClass/DeleteVehicleClass',
    },
    SEGMENTDETAILS: {
      LIST: '/api/SegmentDetails/GetSegmentDetails',
      ADDUPDATESEGMENTDETAILS : '/api/SegmentDetails/AddUpdateSegmentDetails',
      DELETE: '/api/SegmentDetails/DeleteSegmentDetails',
    },
    BODYTYPE: {
      LIST: '/api/BodyType/GetBodyTypes',
      ADDUPDATEBODYTYPE : '/api/BodyType/AddUpdateBodyType',
      DELETE: '/api/BodyType/DeleteBodyType',
    },
    MANUFACTURER: {
      LIST: '/api/Manufacturer/GetManufacturers',
      ADDUPDATEMANUFACTURER : '/api/Manufacturer/AddUpdateManufacturer',
      DELETE: '/api/Manufacturer/DeleteManufacturer',
    },
    FUELTYPE: {
      LIST: '/api/FuelType/GetFuelTypes',
      ADDUPDATEFUELTYPE : '/api/FuelType/AddUpdateFuelType',
      DELETE: '/api/FuelType/DeleteFuelType'
    },
    TRANSMISSION: {
      LIST: '/api/Transmission/GetTransmissions',
      ADDUPDATETRANSMISSION: '/api/Transmission/AddUpdateTransmission',
      DELETE: '/api/Transmission/DeleteTransmission'
    },
    COLORPALATTE: {
      LIST: '/api/ColorPalatte/GetColorPalattes',
      ADDUPDATECOLORPALATTE: '/api/ColorPalatte/AddUpdateColorPalatte',
      DELETE: '/api/ColorPalatte/DeleteColorPalatte'
    }
  },
  SETTINGS:{
    ROLES:{
      GETROLES:'/api/Role/GetRoles',
      GETVIEWROLES:'/api/Role/GetViewRoles',
    },
    STUDYTEMPLATE:{
      UPLOADBULKEXCEL:'/api/Upload/UploadBulkExcel',
    },
    TABLECONFIGURATION:{
      INSERTUPDATETABLECONFIGURATION: '/api/TableConfiguration/InsertUpdateTableColumn',
      INSERTUPDATETABLE: '/api/TableConfiguration/InsertUpdateTable',
      GETTABLECOLUMNS: '/api/TableConfiguration/GetTableColumns',
      GETTABLEINFO: '/api/TableConfiguration/GetTableInfo',
      DELETE: '/api/TableConfiguration/DeleteTable',
      DELETETABLECOLUMN: '/api/TableConfiguration/DeleteTableColumn'
    },
    PAGESETUP:{
      GETPAGES: '/api/PageSetup/GetPages',
      INSERTUPDATEPAGESETUP: '/api/PageSetup/InsertUpdatePageSetup',
      DELETE: '/api/PageSetup/DeletePage',
    },
    FORMDETAILS: {
      LIST: '/api/FormDetail/GetFormDetail',
      GETFORMDETAILSBYTABLEID: '/api/FormDetail/GetFormDetailByTableId',
      GETFORMDETAILSBYPAGEID: '/api/FormDetail/GetFormDetailByPageId',
      GETDETAILBYID: '/api/FormDetail/GetDetailById',
      GETVIEWDATABYID: '/api/FormDetail/GetViewDataById',
      GETVIEWDATABYTID: '/api/FormDetail/GetViewDataByTId',
      GETTABLEINFO: '/api/FormDetail/GetTableInfo',
      ADDUPDATEFORMDETAIL: '/api/FormDetail/InsertUpdateFormDetail',
      DELETE: '/api/FormDetail/DeleteVehicleHistory'
    },
    AUTH:{
      MENULIST: '/api/Auth/GetMenuList',
      GETFORMS: '/api/FormSetup/GetForms',
    },
    CLIENT: {
      ADDUPDATECLIENT: '/api/Client/InsertUpdateClient',
      DELETECLIENT: '/api/Client/DeleteClient',
    },
    DYNAMICFORM: {
      ADDUPDATEDYNAMICFORM: '/api/DynamicForm/InsertUpdateFormDetail',
      DELETEFORMDATADETAIL: '/api/DynamicForm/DeleteFormDataDetail',
    },
    FORMSETUP: {
      LIST: '/api/FormSetup/GetFormSetup',
      ADDUPDATEFORMSETUP: '/api/FormSetup/InsertUpdateFormSetup',
      DELETE: '/api/FormSetup/DeleteFormSetup'
    },

    FORMBLOCKSETUP: {
      LIST: '/api/FormBlockSetup/GetFormBlockSetup',
      GETFORMBLOCKSETUPBYTABLEID: '/api/FormBlockSetup/GetFormBlockSetupTableId',
      GETFORMDETAILBYFORMBLOCKBYPAGEID: '/api/FormBlockSetup/GetFormDetailbyFormBlockByPageId',
      GETFORMBLOCKCONTROLIDS: '/api/FormBlockSetup/GetFormBlockControlIds',
      ADDUPDATEFORMBLOCKSETUP: '/api/FormBlockSetup/InsertUpdateFormBlockSetup',
      GENERATESCRIPT: '/api/FormBlockSetup/GenerateScript',
      ADDUPDATEFORMDETAIL: '/api/FormBlockSetup/InsertUpdateForm',
      DELETE: '/api/FormBlockSetup/DeleteFormBlockSetup'
    },
  },
  VEHICLESETTINGS:{
    VEHICLEHISTORY: {
      LIST: '/api/VehicleHistory/GetVehicleHistories',
      ADDUPDATEVEHICLEHISTORY: '/api/VehicleHistory/AddUpdateVehicleHistory',
      DELETE: '/api/VehicleHistory/DeleteVehicleHistory'
    },
    VEHICLESTATUS: {
      LIST: '/api/VehicleStatus/GetVehicleStatuses',
      ADDUPDATEVEHICLESTATUS: '/api/VehicleStatus/AddUpdateVehicleStatus',
      DELETE: '/api/VehicleStatus/DeleteVehicleStatus'
    },
    VEHICLEOWNER: {
      LIST: '/api/VehicleOwner/GetVehicleOwners',
      ADDUPDATEVEHICLEOWNER: '/api/VehicleOwner/AddUpdateVehicleOwner',
      DELETE: '/api/VehicleOwner/DeleteVehicleOwner'
    }
  },
  ENQUIRYSETTINGS: {
    BUYERTYPE: {
      LIST: '/api/BuyerType/GetBuyerTypes',
      ADDUPDATEBUYERTYPE : '/api/BuyerType/AddUpdateBuyerType',
      DELETE: '/api/BuyerType/DeleteBuyerType',
    },
    ENQUIRYCATEGORY: {
      LIST: '/api/EnquiryCategory/GetEnquiryCategories',
      ADDUPDATEENQUIRYCATEGORY : '/api/EnquiryCategory/AddUpdateEnquiryCategory',
      DELETE: '/api/EnquiryCategory/DeleteEnquiryCategory',
    },
    ENQUIRYOWNER: {
      LIST: '/api/EnquiryOwner/GetEnquiryOwners',
      ADDUPDATEENQUIRYOWNER : '/api/EnquiryOwner/AddUpdateEnquiryOwner',
      DELETE: '/api/EnquiryOwner/DeleteEnquiryCategory',
    },
    ENQUIRYSTATUS: {
      LIST: '/api/EnquiryStatus/GetEnquiryStatuses',
      ADDUPDATEENQUIRYSTATUS : '/api/EnquiryStatus/AddUpdateEnquiryStatus',
      DELETE: '/api/EnquiryStatus/DeleteEnquiryStatus',
    },
    SALUTATION: {
      LIST: '/api/Salutation/GetSalutations',
      ADDUPDATESALUTATION : '/api/Salutation/AddUpdateSalutation',
      DELETE: '/api/Salutation/DeleteSalutation',
    },
    SOURCEOFENQUIRY: {
      LIST: '/api/SourceOfEnquiry/GetSourceOfEnquiries',
      ADDUPDATESOURCEOFENQUIRY : '/api/SourceOfEnquiry/AddUpdateSourceOfEnquiry',
      DELETE: '/api/SourceOfEnquiry/DeleteSourceOfEnquiry',
    },
    DELETE: '/api/vehicleClass/delete/',
    SEARCH: '/api/vehicleClass/search',
    ALL: '/api/vehicleClass/search',
  },
  FINDINGS:{
      LIST: '/api/Finding/GetFindings',
      GETFINDINGBYID: '/api/Finding/GetFindingById',
      ADDUPDATEFINDING: '/api/Finding/AddUpdateFinding',
      DELETE: '/api/Finding/DeleteFinding'
  },
  GENERALSETTINGS:{
    SALUTATION: {
      LIST: '/api/Salutation/GetSalutations',
      ADDUPDATESALUTATION: '/api/Salutation/AddUpdateSalutation',
      DELETE: '/api/Salutation/DeleteSalutation'
    },
    GENDER: {
      LIST: '/api/Gender/GetGenders',
      ADDUPDATEGENDER: '/api/Gender/AddUpdateGender',
      DELETE: '/api/Gender/DeleteGender'
    },
    SCHEMETYPE: {
      LIST: '/api/SchemeType/GetSchemeTypes',
      ADDUPDATESCHEMETYPE: '/api/SchemeType/AddUpdateSchemeType',
      DELETE: '/api/SchemeType/DeleteSchemeType'
    },
    SCHEMECATEGORY: {
      LIST: '/api/SchemeCategory/GetSchemeCategories',
      ADDUPDATESCHEMECATEGORY: '/api/SchemeCategory/AddUpdateSchemeCategory',
      DELETE: '/api/SchemeCategory/DeleteSchemeCategory'
    },
    INSURANCECOMPANY: {
      LIST: '/api/InsuranceCompany/GetInsuranceCompanies',
      ADDUPDATEINSURANCECOMPANY: '/api/InsuranceCompany/AddUpdateInsuranceCompany',
      DELETE: '/api/InsuranceCompany/DeleteInsuranceCompany'
    },
    FINANCEARRANGEBY: {
      LIST: '/api/FinanceArrangeBy/getFinanceArrangeBies',
      ADDUPDATEFINANCEARRANGEBY: '/api/FinanceArrangeBy/AddUpdateFinanceArrangeBy',
      DELETE: '/api/FinanceArrangeBy/DeleteFinanceArrangeBy'
    },
    BANKNAME: {
      LIST: '/api/BankName/GetBankNames',
      ADDUPDATEBANKNAME: '/api/BankName/AddUpdateBankName',
      DELETE: '/api/BankName/DeleteBankName'
    },
    FOCUSAREA: {
      LIST: '/api/FocusArea/GetFocusAreas',
      ADDUPDATEFOCUSAREA: '/api/FocusArea/AddUpdateFocusArea',
      DELETE: '/api/FocusArea/DeleteFocusArea'
    },
    RELATIONSHIP: {
      LIST: '/api/RelationShip/GetRelationShips',
      ADDUPDATERELATIONSHIP: '/api/RelationShip/AddUpdateRelationShip',
      DELETE: '/api/RelationShip/DeleteRelationShip'
    },
    CUSTOMERTYPE: {
      LIST: '/api/CustomerType/GetCustomerTypes',
      ADDUPDATECUSTOMERTYPE: '/api/CustomerType/AddUpdateCustomerType',
      DELETE: '/api/CustomerType/DeleteCustomerType'
    },
    VISIT: {
      LIST: '/api/Visit/GetVisits',
      ADDUPDATEVISIT: '/api/Visit/AddUpdateVisit',
      DELETE: '/api/Visit/DeleteVisit'
    },
    HYPOTHECATED: {
      LIST: '/api/Hypothecated/GetHypothecateds',
      ADDUPDATEHYPOTHECATED: '/api/Hypothecated/AddUpdateHypothecated',
      DELETE: '/api/Hypothecated/DeleteHypothecated'
    } 
  }
};
