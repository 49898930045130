import * as Yup from 'yup';
import { getFormBlockSetupTableId } from "src/redux/slices/settings/form-block-setup";
import { getDetailById } from "src/redux/slices/settings/table-configuration";


export function newFormGetFormBlockSetupTableId(id: any, viewId: any, watchFields: any, setWatchFields: any, setSelectedRow: any, isPopup: boolean = true, quickEdit: any = undefined) {

    getFormBlockSetupTableId(viewId).then((res: any) => {
        const resData = res?.data?.data || [];
        const ddlData: any = [];
        res?.data?.data?.forEach((item: any) => {
            if (item.subFormId > 0) {
                item[item.subFormName] = {};
            }
            item?.formDetail?.forEach((control: any) => {
                if (item.subFormId > 0) {
                    item[item.subFormName] = {};
                    const columnName = `${(item.subFormName ? `${item.subFormName}.` : "")}${control.columnName}`;
                    const dependencyDropdownKeyCol = `${(item.subFormName ? `${item.subFormName}.` : "")}${control.dependencyDropdownKeyCol}`;
                    const dependencyField = `${(item.subFormName  && control.dependencyField? `${item.subFormName}.` : "")}${control.dependencyField}`;
                    if (control.inputType === "COLORPICKER") {
                        watchFields.push(control.columnName);
                    }
                    if (control.dependencyField) {
                        watchFields.push(dependencyField);
                    }
                    if (control.dependencyDropdownKeyCol) {
                        watchFields.push(dependencyDropdownKeyCol);
                        watchFields.push(columnName);
                    }
                    if (control.refDependencyDropdownKeyCol) {
                        watchFields.push(columnName);
                    }
                    if (!control.dependencyDropdownKeyCol)
                        ddlData[`ddlData${control.columnName}`] = (control.dropdownKeyData && JSON.parse(control.dropdownKeyData)) || [];
                    else
                        ddlData[`ddlData${control.columnName}`] = [];
                } else {
                    if (control.inputType === "COLORPICKER") {
                        watchFields.push(control.columnName);
                    }
                    if (control.dependencyField) {
                        watchFields.push(control.dependencyField);
                    }
                    if (control.dependencyDropdownKeyCol) {
                        watchFields.push(control.dependencyDropdownKeyCol);
                        watchFields.push(control.columnName);
                    }
                    if (control.refDependencyDropdownKeyCol) {
                        watchFields.push(control.columnName);
                    }
                    if (!control.dependencyDropdownKeyCol)
                        ddlData[`ddlData${control.columnName}`] = (control.dropdownKeyData && JSON.parse(control.dropdownKeyData)) || [];
                    else
                        ddlData[`ddlData${control.columnName}`] = [];
                }
            })
        })
        setWatchFields([...watchFields]);
        const watchList: any = watchFields.length > 0 ? [...new Set([...watchFields])] : [];
        if (id > 0) {
            getDetailById(id, viewId).then((result: any) => {
                if (result.data && result.data.data) {
                    const data = JSON.parse(result.data.data);
                    setSelectedRow({ ...data[0], watchFields: watchList, formBlockList: resData, ddlData } as any);
                    quickEdit?.onTrue()
                }
            })
        } else {
            setSelectedRow({ id: 0, status: true, watchFields: [...new Set([...watchFields])], formBlockList: resData, ddlData } as any);
            quickEdit?.onTrue()
        }
    })
}

export const bindDataModel = (controlObj: any, shape: any, shapeDetail: any, subFormName: any, currentModel: any, setValue: any) => {
    const columnName = `${(subFormName ? `${subFormName}.` : "")}${controlObj.columnName}`;
    const columnNameValue = (subFormName && subFormName !== "") ? currentModel[subFormName][controlObj.columnName] : currentModel[controlObj.columnName];

    switch (controlObj.dataType) {
        case "BIT": {
            if (controlObj.subFormId > 0) {
                controlObj[columnName] = false
                currentModel[columnName] = false
            };
            setValue(columnName, columnNameValue || false)
            shape[columnName] = controlObj.required ? Yup.string().required(controlObj.requiredErrorMessage) : Yup.string();
            shapeDetail[controlObj.columnName] = controlObj.required ? Yup.string().required(controlObj.requiredErrorMessage) : Yup.string();
            break;
        }
        case "DATETIME": {
            if (controlObj.subFormId > 0) {
                controlObj[columnName] = new Date()
                currentModel[columnName] = new Date()
            };
            setValue(columnName, columnNameValue || new Date())
            shape[columnName] = controlObj.required ? Yup.string().required(controlObj.requiredErrorMessage) : Yup.string();
            shapeDetail[controlObj.columnName] = controlObj.required ? Yup.string().required(controlObj.requiredErrorMessage) : Yup.string();
            break;
        }
        case "NVARCHAR(MAX)": {
            if (controlObj.subFormId > 0) {
                controlObj[columnName] = ""
                currentModel[columnName] = ""
            };
           
            setValue(columnName, columnNameValue || "")
            shape[columnName] = controlObj.required ? Yup.string().required(controlObj.requiredErrorMessage) : Yup.string();
            shapeDetail[controlObj.columnName] = controlObj.required ? Yup.string().required(controlObj.requiredErrorMessage) : Yup.string();
            break;
        }
        case "INT": {
            if (controlObj.inputType === "DROPDOWN") {
                if (controlObj.subFormId > 0) {
                    controlObj[columnName] = ""
                    currentModel[columnName] = ""
                };
                setValue(columnName, columnNameValue || 0)
                shape[columnName] = controlObj.required ? Yup.string().required(controlObj.requiredErrorMessage) : Yup.string();
                shapeDetail[controlObj.columnName] = controlObj.required ? Yup.string().required(controlObj.requiredErrorMessage) : Yup.string();
            } else {
                if (controlObj.subFormId > 0) {
                    controlObj[columnName] = 0
                    currentModel[columnName] = 0
                };
                setValue(columnName, columnNameValue || '')
                shape[columnName] = controlObj.required ? Yup.number().required(controlObj.requiredErrorMessage) : Yup.number();
                shapeDetail[controlObj.columnName] = controlObj.required ? Yup.number().required(controlObj.requiredErrorMessage) : Yup.number();
            }
            break;
        }
        default:
            break;
    }
}

export const formBlockListData = (shape: any, shapeSubForm: any, formBlockList: any, currentModel: any, setValue: any, tabFormBlockList: any, setNewModelSchema: any, setNewModelSchemaSubForm: any,setSchemaPageWise?:any) => {
    formBlockList?.forEach((item: any) => {
        if (item.blockType !== "MULTIPLE" && item.blockType !== "MULTIPLECOLLAPAPSIBLE") {
            let isNull: boolean = false;
            if (item.subFormId > 0) {
                isNull = true;
                if (typeof currentModel[item.subFormName] === 'string') {
                    currentModel[item.subFormName] = JSON.parse(currentModel[item.subFormName]);
                    if ((currentModel[item.subFormName].Id || currentModel[item.subFormName].ID)) {
                        setValue(item.subFormName, currentModel[item.subFormName])
                        setValue(`${item.subFormName}.ID`, (currentModel[item.subFormName].Id || currentModel[item.subFormName].ID) || "0")
                    } else {
                        item[item.subFormName] = currentModel[item.subFormName];
                        setValue(`${item.subFormName}.ID`, (currentModel[item.subFormName].Id || currentModel[item.subFormName].ID) || "0")
                    }
                } else {
                    currentModel[item.subFormName] = {} as any;
                    item[item.subFormName] = {} as any;
                    setValue(`${item.subFormName}.ID`, "0")
                }
            }

            const shapeDetail: any = {} as any;
            item?.formDetail?.forEach((controlObj: any) => {
                bindDataModel(controlObj, {}, shapeDetail, item.subFormName, currentModel, setValue);
            });
            if (item.subFormName) {
                shape = { ...shape, [item.subFormName]: Yup.object({ ...shapeDetail }) };
            } else {
                shape = { ...shape, ...shapeDetail };
            }
        } else if (item.blockType === "MULTIPLE" || item.blockType === "MULTIPLECOLLAPAPSIBLE") {
            item?.subFormDetail.forEach((item1: any) => {
                const blockDataSub = item1.blockType !== "MULTIPLE" || item.blockType !== "MULTIPLECOLLAPAPSIBLE" ? item1?.formDetail : [];
                if (item.subFormId > 0) {
                    item[`${item.subFormName}FORM`] = {};
                    if (currentModel[`${item.subFormName}FORM`] == null) currentModel[`${item.subFormName}FORM`] = {} as any;
                    setValue(`${item.subFormName}FORM.ID`, currentModel[`${item.subFormName}.ID`] || "0")
                }
                const shapeDetail: any = {} as any;
                blockDataSub?.forEach((controlObj: any) => {
                    if (item.subFormId > 0) item[`${item.subFormName}FORM`][controlObj.columnName] = '';
                    bindDataModel(controlObj, {}, shapeDetail, `${item.subFormName}FORM`, currentModel, setValue);
                })
                if (item.subFormId) {
                    shapeSubForm = { ...shapeSubForm, [`${item.subFormName}FORM`]: Yup.object({ ...shapeDetail }) };
                } else {
                    shapeSubForm = { ...shapeSubForm, ...shapeDetail };
                }
            });
        }
    });
    tabFormBlockList?.forEach((item: any) => {
        if (item.blockType !== "MULTIPLE" && item.blockType !== "MULTIPLECOLLAPAPSIBLE") {
            let isNull: boolean = false;
            if (item.subFormId > 0) {
                isNull = true;
                if (typeof currentModel[item.subFormName] === 'string') {
                    currentModel[item.subFormName] = JSON.parse(currentModel[item.subFormName]);
                    if ((currentModel[item.subFormName].Id || currentModel[item.subFormName].ID)) {
                        setValue(item.subFormName, currentModel[item.subFormName])
                        setValue(`${item.subFormName}.ID`, (currentModel[item.subFormName].Id || currentModel[item.subFormName].ID) || "0")
                    } else {
                        item[item.subFormName] = currentModel[item.subFormName];
                        setValue(`${item.subFormName}.ID`, (currentModel[item.subFormName].Id || currentModel[item.subFormName].ID) || "0")
                    }
                } else {
                    currentModel[item.subFormName] = {} as any;
                    item[item.subFormName] = {} as any;
                    setValue(`${item.subFormName}.ID`, "0")
                }
            }

            const shapeDetail: any = {} as any;
            item?.formDetail?.forEach((controlObj: any) => {
                bindDataModel(controlObj, {}, shapeDetail, item.subFormName, currentModel, setValue);
            });
            if (item.subFormName) {
                shape = { ...shape, [item.subFormName]: Yup.object({ ...shapeDetail }) };
            } else {
                shape = { ...shape, ...shapeDetail };
            }
        } else if (item.blockType === "MULTIPLE" || item.blockType === "MULTIPLECOLLAPAPSIBLE") {
            item?.subFormDetail.forEach((item1: any) => {
                const blockDataSub = item1.blockType !== "MULTIPLE" || item.blockType !== "MULTIPLECOLLAPAPSIBLE" ? item1?.formDetail : [];
                if (item.subFormId > 0) {
                    item[`${item.subFormName}FORM`] = {};
                    if (currentModel[`${item.subFormName}FORM`] == null) currentModel[`${item.subFormName}FORM`] = {} as any;
                    setValue(`${item.subFormName}FORM.ID`, currentModel[`${item.subFormName}.ID`] || "0")
                }
                const shapeDetail: any = {} as any;
                blockDataSub?.forEach((controlObj: any) => {
                    if (item.subFormId > 0) item[`${item.subFormName}FORM`][controlObj.columnName] = '';
                    bindDataModel(controlObj, {}, shapeDetail, `${item.subFormName}FORM`, currentModel, setValue);
                })
                if (item.subFormId) {
                    shapeSubForm = { ...shapeSubForm, [`${item.subFormName}FORM`]: Yup.object({ ...shapeDetail }) };
                } else {
                    shapeSubForm = { ...shapeSubForm, ...shapeDetail };
                }
            });
        }
    });
    currentModel.shape = { ...shape };
    currentModel.shapeSubForm = { ...shapeSubForm };
    setNewModelSchema(Yup.object().shape({ ...shape }));
    setNewModelSchemaSubForm(Yup.object().shape({ ...shapeSubForm }));
    if(setSchemaPageWise) {
        const subFormName = formBlockList[0].subFormName;
        setSchemaPageWise(Yup.object().shape({ [subFormName]:shape[subFormName] }))
    }
}

export const conditionFlag = (subFormName: any, controlObj: any, getValue: any) => {
    let show: any = 'flex';
    let enable: any = true;
    if (controlObj.dependencyField) {
        const conditionalLogic = getValue(`${subFormName}${controlObj.dependencyField}`) === controlObj.conditionalLogic;
        if (conditionalLogic) {
            if (controlObj.actionRequired === "Hide") show = "none";
            else if (controlObj.actionRequired === "Show") show = "flex";

            if (controlObj.actionRequired === "Enable") enable = true;
            else if (controlObj.actionRequired === "Disable") enable = false;

        } else if (!getValue(`${subFormName}${controlObj.dependencyField}`)) {
            if (controlObj.actionRequired === "Hide" || controlObj.actionRequired === "Show") show = "none";
            if (controlObj.actionRequired === "Enable" || controlObj.actionRequired === "Disable") enable = false;
        }
    }
    return { show, enable }
}