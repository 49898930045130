import { Helmet } from 'react-helmet-async';
import DealerClassicLayout from 'src/layouts/auth/dealer';
// sections
import JwtClientLoginView from 'src/sections/auth/jwt/client/jwt-client-login-view';

// ----------------------------------------------------------------------

export default function ClientLoginPage() {
  return (
    <DealerClassicLayout>
    <>
      <Helmet>
        <title> SclinEDC: Login</title>
      </Helmet>

      <JwtClientLoginView />
    </>
    </DealerClassicLayout>
  );
}
