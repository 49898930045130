import { Autocomplete, Chip, Divider, FormControlLabel, FormHelperText, Grid, IconButton, MenuItem, Radio, RadioGroup, Stack, TextField, Tooltip, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { ColorPicker } from 'material-ui-color';
import moment from 'moment';
import { Fragment, useCallback, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useDateRangePicker } from 'src/components/custom-date-range-picker';
import Editor from 'src/components/editor';
import { RHFAutocomplete, RHFEditor, RHFRadioGroup, RHFSelect, RHFSwitch, RHFTextField, RHFUploadAvatar } from 'src/components/hook-form';
import Iconify from 'src/components/iconify';
import { CustomFile, Upload } from "src/components/upload";
import { useLocales } from 'src/locales';
import { GLOBALCLASSINPUT } from 'src/utils/contants/config-common';
import { conditionFlag } from 'src/utils/dynamic.form.generation.common.code';
import { fData } from 'src/utils/format-number';

interface FormValuesProps extends Omit<any, 'avatarUrl'> {
    avatarUrl: CustomFile | string | null;
}

type Props = {
    formDetails: any;
    currentData: any;
    indexValue: any;
    setValue: any;
    getValues: any;
    control: any;
    subFormName?: any;
    inputTypeSize?: any;
    onAttachmentClick?: any;
    onNotesClick?: any;
    onSubmitQueryClick?: any;
};

export default function DynamicFormTemplate({ formDetails, setValue, getValues, control, currentData, indexValue, subFormName = "", inputTypeSize, onAttachmentClick, onNotesClick, onSubmitQueryClick }: Props) {
    const { t } = useLocales();
    const rangeInputPicker = useDateRangePicker(new Date(), new Date());

    const handleDropSingleFile = useCallback((acceptedFiles: File[], column: any) => {
        const file = acceptedFiles[0];
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file)
        fileReader.onload = () => {
            console.log(fileReader.result);
            setValue(column, fileReader.result, { shouldValidate: true });
        }
        fileReader.onerror = (error) => {
            console.log(error);
        }
    }, [setValue])

    const renderIcons = useCallback((controlObj: any) =>
        <Grid item xs={12} lg={3}  sx={{ float: 'right',textAlign:"right" }}>
            {controlObj && controlObj.attachment && <Tooltip key={`${crypto.randomUUID()}`} title="Quick Attachment" placement="top" arrow>
                <IconButton key={`${crypto.randomUUID()}`} onClick={() => { onAttachmentClick(controlObj) }}>
                    <Iconify key={`${crypto.randomUUID()}`} icon="ic:baseline-attachment" />
                </IconButton>
            </Tooltip>
            }
            {controlObj && controlObj.notes && <Tooltip key={`${crypto.randomUUID()}`} title="Quick Note" placement="top" arrow>
                <IconButton key={`${crypto.randomUUID()}`} onClick={() => { onNotesClick(controlObj) }}>
                    <Iconify key={`${crypto.randomUUID()}`} icon="hugeicons:note-edit" />
                </IconButton>
            </Tooltip>
            }
            {controlObj && controlObj.query && <Tooltip key={`${crypto.randomUUID()}`} title="Submit Query" placement="top" arrow>
                <IconButton key={`${crypto.randomUUID()}`} onClick={() => { onSubmitQueryClick(controlObj) }}>
                    <Iconify key={`${crypto.randomUUID()}`} icon="ph:question-fill" />
                </IconButton>
            </Tooltip>
            }
            {controlObj && controlObj.clear && <Tooltip key={`${crypto.randomUUID()}`} title="Clear All" placement="top" arrow>
                <IconButton key={`${crypto.randomUUID()}`} onClick={() => {
                    setValue(`${subFormName}${controlObj.columnName}`, '');

                }}>
                    <Iconify key={`${crypto.randomUUID()}`} icon="f7:clear-fill" />
                </IconButton>
            </Tooltip>
            }
        </Grid>
        , [])

    const lgWidth = (controlObj: any) => controlObj.attachment || controlObj.notes || controlObj.query || controlObj.clear ? 9 : 12

    const renderForm = useCallback((formDetail: any, index: any) => {
        const newRecords = formDetail && formDetail.map((controlObj: any, controlIndex: any) => {
            const randomUUID: any = crypto.randomUUID();
            const conditionFlags = conditionFlag(subFormName, controlObj, getValues);
            switch (controlObj.inputType) {
                case "TEXTAREA":
                return <Controller key={`${randomUUID}1`}
                name={`${subFormName}${controlObj.columnName}`}
                control={control}
                render={({ field, fieldState: { error } }) => (
                    <Editor key={`${randomUUID}2`}
                        id={`${subFormName}${controlObj.columnName}`}
                        onChange={field?.onChange}
                        value={field?.value?field?.value:""}
                        error={!!error}
                        helperText={
                            (!!error) && (
                                <FormHelperText key={`${randomUUID}3`} error={!!error} sx={{ px: 2 }}>
                                    {error ? error?.message : "helperText"}
                                </FormHelperText>
                            )
                        }
                    />
                )}
            />
        // <RHFEditor  simple key={Math.random()} simple sx={{ height: 200 }} name={`${subFormName}${controlObj.columnName}`} onChange={(value:any) => console.log(value)} />
        case "DATETIME": {
                    return <Grid key={`${crypto.randomUUID()}`} container style={{ "display": conditionFlags?.show }}>
                        <Grid key={`${crypto.randomUUID()}`} item xs={12} lg={lgWidth(controlObj)}>
                            <Controller key={`${randomUUID}4`}
                                name={`${crypto.randomUUID()}`}
                                control={control}
                                render={({ field, fieldState: { error } }) => (
                                    <DatePicker disabled={!conditionFlags?.enable} key={`${randomUUID}5`}
                                        label={controlObj.label}
                                        onChange={(newValue: any) => {
                                            console.log(newValue);
                                            console.log(moment(newValue).format("MM/DD/YY"))
                                            setValue(`${subFormName}${controlObj.columnName}`, moment(newValue).format("MM/DD/YY"));
                                            field.onChange(newValue);
                                        }}
                                        format="dd-MMM-yyyy"
                                        slotProps={{
                                            textField: {
                                                size: 'small',
                                                error: !!error,
                                                helperText: error?.message,
                                            },
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        {renderIcons(controlObj)}
                    </Grid>
                }
                case "PASSWORD":
                    return <Grid key={`${crypto.randomUUID()}`} container style={{ "display": conditionFlags?.show }}>
                        <Grid key={`${crypto.randomUUID()}`} item xs={12} lg={lgWidth(controlObj)} display={{ xs: 'none', lg: 'flex' }}>
                            <RHFTextField disabled={!conditionFlags?.enable} autoComplete='off' type='password' id={`${subFormName}${controlObj.columnName}`} key={`${randomUUID}6`} size={inputTypeSize} name={`${subFormName}${controlObj.columnName}`} label={t(controlObj.label)} />
                        </Grid>
                        {renderIcons(controlObj)}
                    </Grid>

                case "NUMBER":
                    return <Grid key={`${crypto.randomUUID()}`} container style={{ "display": conditionFlags?.show }} >
                        <Grid key={`${crypto.randomUUID()}`} item xs={12} lg={lgWidth(controlObj)} display={{ xs: 'none', lg: 'flex' }}>
                        <TextField disabled={!conditionFlags?.enable} autoComplete='off' type='number' id={controlObj.columnName} key={`${randomUUID}7`} size={inputTypeSize} name={`${subFormName}${controlObj.columnName}`} label={t(controlObj.label)} />
                            {/* <RHFTextField disabled={!conditionFlags?.enable} autoComplete='off' type='number' id={controlObj.columnName} key={`${randomUUID}7`} size={inputTypeSize} name={`${subFormName}${controlObj.columnName}`} label={t(controlObj.label)} /> */}
                        </Grid>
                        {renderIcons(controlObj)}
                    </Grid>
                case "EMAILADDRESS":
                    return <Grid key={`${crypto.randomUUID()}`} container style={{ "display": conditionFlags?.show }}>
                        <Grid key={`${crypto.randomUUID()}`} item xs={12} lg={lgWidth(controlObj)} >
                            <RHFTextField disabled={!conditionFlags?.enable} type='email' autoComplete='off' key={`${randomUUID}8`} id={`${subFormName}${controlObj.columnName}`} size={inputTypeSize} name={`${subFormName}${controlObj.columnName}`} label={t(controlObj.label)} />
                        </Grid>
                        {renderIcons(controlObj)}
                    </Grid>
                case "TEXTBOX": {
                    return <Grid key={`${crypto.randomUUID()}`} container style={{ "display": conditionFlags?.show }}>
                        <Grid key={`${crypto.randomUUID()}`} item xs={12} lg={lgWidth(controlObj)}  >
                            <RHFTextField autoComplete='off' disabled={!conditionFlags?.enable} key={`${randomUUID}8`} id={`${subFormName}${controlObj.columnName}`} size={inputTypeSize} name={`${subFormName}${controlObj.columnName}`} label={t(controlObj.label)} />
                        </Grid>
                        {renderIcons(controlObj)}
                    </Grid>
                }
                case "DROPDOWN": {
                    let optionData: any = !controlObj.dependencyDropdownKeyCol ? controlObj.dropdownKeyData && JSON.parse(controlObj.dropdownKeyData) : controlObj.dropdownKeyData && JSON.parse(controlObj.dropdownKeyData)?.filter((x: any) => parseInt(x[controlObj.dependencyDropdownKeyCol], 10) === parseInt(getValues()[controlObj.parentColumnName], 10));
                    optionData = typeof optionData === 'string' ? [] : optionData
                    return <RHFSelect key={`${randomUUID}9`} id={`${subFormName}${controlObj.columnName}`} name={`${subFormName}${controlObj.columnName}`}
                        label={t(controlObj.label)} size={inputTypeSize}
                        onChange={(e) => {
                            const filterData = optionData?.filter((x: any) => x.Id === e.target.value)[0]
                            setValue(`${subFormName}${controlObj.columnName}1`, filterData[controlObj.dropdownLabelName]);
                            setValue(`${subFormName}${controlObj.columnName}`, e.target.value);
                            if (controlObj.childReference) {
                                controlObj.childReference.split(',').forEach((item: any) => {
                                    const dataD = (controlObj.dropdownKeyData && JSON.parse(controlObj.dropdownKeyData)).filter((x: any) => x.Id === e.target.value)[0][item];
                                    setValue(controlObj.refDependencyDropdownKeyCol, dataD)
                                })
                            }
                        }}
                    >
                        <MenuItem key={`${randomUUID}10`} value=''>None</MenuItem>
                        <Divider key={`${randomUUID}11`} sx={{ borderStyle: 'dashed' }} />
                        {optionData?.map((item: any) => (
                            <MenuItem key={`${randomUUID}${item.columnName}${item.Id}`} value={item.Id}> {item[controlObj.dropdownLabelName]} </MenuItem>
                        ))}
                    </RHFSelect>
                }
                case "MULTISELECT": {
                    let optionData: any = !controlObj.dependencyDropdownKeyCol ? controlObj.dropdownKeyData && JSON.parse(controlObj.dropdownKeyData) : controlObj.dropdownKeyData && JSON.parse(controlObj.dropdownKeyData)?.filter((x: any) => parseInt(x[controlObj.dependencyDropdownKeyCol], 10) === parseInt(getValues()[controlObj.parentColumnName], 10));
                    optionData = typeof optionData === 'string' ? [] : optionData
                    let defaultControlValuesList: any = [];
                    defaultControlValuesList = optionData.filter((x: any) => getValues()[`${subFormName}${controlObj.columnName}`]?.split(',').includes(x.Id.toString()))
                    return <>

                        <Autocomplete key={`${randomUUID}12`}
                            id={`${subFormName}${controlObj.columnName}`}
                            fullWidth
                            multiple
                            size={inputTypeSize}
                            options={optionData}
                            defaultValue={defaultControlValuesList}
                            // isOptionEqualToValue={(option: any, value: any) => option.label === value.label}
                            getOptionLabel={(option: any) => option[controlObj.dropdownLabelName]}
                            onChange={(event, newValue) => {
                                let ids = "";
                                newValue?.forEach((item) => {
                                    ids += `${item.Id},`;
                                });
                                // setValue("controlIds", ids);
                                const filterData = optionData?.filter((x: any) => ids?.split(',').includes(x.Id.toString()))
                                let cancatinate: any = "";
                                filterData.forEach((element: any) => {
                                    if (filterData.length === 1) {
                                        cancatinate += `${element[controlObj.dropdownLabelName]}`;
                                    } else {
                                        cancatinate += `${element[controlObj.dropdownLabelName]},`;
                                    }
                                });
                                setValue(`${subFormName}${controlObj.columnName}1`, cancatinate);
                                setValue(`${subFormName}${controlObj.columnName}`, ids);
                            }}
                            renderInput={(params) => (
                                <TextField key={`${randomUUID}13`}  {...params} label={t(controlObj.label)} placeholder={controlObj.label} />
                            )}
                            renderOption={(props, option: any) => (
                                <li key={`${randomUUID}14`} {...props} >
                                    {option[controlObj.dropdownLabelName]}
                                </li>
                            )}
                            renderTags={(selected, getTagProps) =>
                                selected.map((option: any, indexD: any) => (
                                    <Chip
                                        {...getTagProps({ index: indexD })}
                                        key={`1${randomUUID}${indexD}`}
                                        label={option[controlObj.dropdownLabelName]}
                                        size={GLOBALCLASSINPUT}
                                        color="info"
                                        variant="soft"
                                    />
                                ))
                            }
                        />
                    </>

                }
                case "COLORPICKER": {
                    return <Stack spacing={1} sx={{ p: 1 }} key={`${crypto.randomUUID()}`} display="grid" gridTemplateColumns={{ xs: 'repeat(1, 1fr)', sm: `repeat(2, 1fr)` }}> {controlObj.label ? controlObj.label : 'Pick Color'}:
                        <ColorPicker disableAlpha key={`dynamic${controlObj.columnName}`} defaultValue={currentData[`${subFormName}${controlObj.columnName}`]} value={getValues()[`${subFormName}${controlObj.columnName}`]}
                            onChange={(data: any) => {
                                setValue(`${subFormName}${controlObj.columnName}`, data.css.backgroundColor);
                            }} />
                    </Stack>
                }
                case "FILEUPLOADs": {
                    return <RHFUploadAvatar key={`${crypto.randomUUID()}`} minSize={20}
                        helperText={
                            <Typography key={`${crypto.randomUUID()}`}
                                variant="caption"
                                sx={{
                                    mt: 3,
                                    mx: 'auto',
                                    display: 'block',
                                    textAlign: 'center',
                                    color: 'text.disabled',
                                }}
                            >
                                Allowed *.jpeg, *.jpg, *.png, *.gif
                                <br /> max size of {fData(3145728)}
                            </Typography>
                        }
                        name={`${subFormName}${controlObj.columnName}`}
                        onDrop={(e: any) => handleDropSingleFile(e, `${subFormName}${controlObj.columnName}`)}
                        onDelete={() => setValue(`${subFormName}${controlObj.columnName}`, '', { shouldValidate: true })}
                    />
                }
                case "FILEUPLOAD": {
                    return <Upload onDrop={(e: any) => handleDropSingleFile(e, `${subFormName}${controlObj.columnName}`)} key={`${crypto.randomUUID()}`} onDelete={() => setValue(`${subFormName}${controlObj.columnName}`, '', { shouldValidate: true })} />
                }
                case "CHECKBOX": {
                    return <RHFSwitch id={`${subFormName}${controlObj.columnName}`} key={`${crypto.randomUUID()}`} defaultValue={currentData[`${subFormName}${controlObj.columnName}`] ? currentData[`${subFormName}${controlObj.columnName}`] : false} name={`${subFormName}${controlObj.columnName}`} label={t(controlObj.checkboxLabel)} />
                }
                case "RADIOBUTTON": {
                    const radioOption =  controlObj?.options?.split(',')?.map((option: any, indexD: any) => ({ label:option, value: option }))
                    return <Grid key={`${crypto.randomUUID()}`} container>
                        <Grid key={`${crypto.randomUUID()}`} item xs={12} lg={6} display={{ xs: 'none', lg: 'flex' }}>
                            {t(controlObj.label)}
                        </Grid>
                        <Grid key={`${crypto.randomUUID()}`} item xs={12} lg={3} display={{ xs: 'none', lg: 'flex' }}>
                        <RHFRadioGroup row name={`${subFormName}${controlObj.columnName}`} options={[...radioOption]}/>
                        </Grid>
                        {renderIcons(controlObj)}
                    </Grid>
                }
                case "AUTOCOMPLETE": {
                    const autoComplete =  controlObj?.options?.split(',')||[];
                    return  <RHFAutocomplete key={`${crypto.randomUUID()}`}
                    name= {`${subFormName}${controlObj.columnName}`}
                    label={controlObj.label}
                    options={autoComplete}
                    getOptionLabel={(option: any) => (option)}
                    isOptionEqualToValue={(option: any, value: any) => option === value}
                    renderOption={(props: any, option: any) => (
                      <li key={`${crypto.randomUUID()}`} {...props}>
                        {option}
                      </li>
                    )}
                  />
                }
                
                default: return "";
            }
        })
        return newRecords;
    }, [getValues])

    return (
        <Fragment key={Math.random()}>
            {renderForm(formDetails, indexValue)}
        </Fragment>
    )
}