import { useCallback, useEffect, useMemo, useState } from 'react';
// routes
import { paths } from 'src/routes/paths';
// locales
import { useLocales } from 'src/locales';
// components
import Iconify from 'src/components/iconify';
import SvgColor from 'src/components/svg-color';
import { useAuthContext } from 'src/auth/hooks';
import { NavListProps } from 'src/components/nav-section';
import { menuItemClasses } from '@mui/material';

// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  job: icon('ic_job'),
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
};

// ----------------------------------------------------------------------

export function useNavData() {
  const { t } = useLocales();
  const { user } = useAuthContext();
  const [menuItem,setMenuItem] = useState([] as NavListProps[]);
  const [menuItem0,setMenuItem0] = useState([] as any[]);
  const [menuItem1,setMenuItem1] = useState([] as any[]);
  

  const addMenuChildren = useCallback((dt: any[], dtchild: any[]) => {
    dtchild.forEach((dr: any) => {
      const subchild2 = dt.filter(p => p.parentId === dr.id)
      const subchild: NavListProps[] =[];
      subchild2.forEach(x=>{
        const item:NavListProps = {} as NavListProps
        item.title =t(x.title);
        item.icon = x.icon;
        item.path = x.path;
        item.id=x.id;
        item.parentId=x.parentId;
        subchild.push({...item});
      })
      dr.title =t(dr.title); 
      dr.path =`${dr.path.indexOf(':id')===-1?dr.path:dr.path.replace(":id",dr.id)}` ;
      if (subchild != null && subchild.length > 0) {
        dr.children = subchild;
        const dtsubchild: NavListProps[] = subchild;
        addMenuChildren(dt, dtsubchild);
      }
    })
    return dtchild;
  },[t])

  const getMenuStructure = useCallback((dt: any[]) => {
    let parent: NavListProps[] = [];
    const dtparent: NavListProps[] =[];
    if (dt != null && dt.length > 0) {
      parent = dt.filter(p => p.parentId === 0)

      if (parent != null && parent.length > 0) {
        parent.forEach(x=>{
          const item:NavListProps = {} as NavListProps
          item.title =t(x.title);
          item.icon = x.icon;
          item.path = x.path;
          item.id=x.id;
          item.parentId=x.parentId;
          item.tableInfoId=x.tableInfoId;
          dtparent.push({...item});
        })

        dtparent.forEach((dr:any) => {
          const child1 = dt.filter(p => p.parentId === dr.id);
          const child: NavListProps[] =[];
          child1.forEach(x=>{
            const item:NavListProps = {} as NavListProps
            item.title =t(x.title);
            item.icon = x.icon;
            item.path = x.path;
            item.id=x.id;
            item.parentId=x.parentId;
            item.tableInfoId=x.tableInfoId;
            child.push({...item});
          })
          if (child != null && child.length > 0) {
            dr.children = child;
            const dtchild: any[] = child;
            addMenuChildren(dt, dtchild);
          }
        })
      }
    }
    return dtparent;
  },[addMenuChildren,t])


  useEffect(()=>{
    const menutItems:any[] = user?.menuItems || [];
    // const data:NavListProps[] = getMenuStructure(menutItems)
    const menuItemsLevel0:any[]= menutItems.filter(x=>x.level===0);
    const menuItemsLevel1:any[]= menutItems.filter(x=>x.level===1);
    const dataLevel0:any[] = getMenuStructure(menuItemsLevel0)
    const data:any[] = getMenuStructure(menuItemsLevel1)
    // setMenuItem(data);
    setMenuItem0(dataLevel0);
    setMenuItem1(data);
  },[user,getMenuStructure])

  const data = useMemo(
    () => [
      // OVERVIEW
      // ----------------------------------------------------------------------
      {
        subheader: t('overview'),
        items: menuItem0, 
      },
      {
        subheader: t('settings'),
        items: menuItem1, 
      },
    ],
    [t,menuItem0,menuItem1]
  );
  return data;
}
