export const DATEFORMAT=""

export const CONSTANTDEFAULTPAGES = {
    REGISTRATIONPAGE:36,
    LOGIN:36,
    FORGOTPASSWORD:39,
    CLIENTREGISTRATIONPAGE:111,
    CLINETFORGOTPASSWORD:39,
    ATTACHMENT:102,
    NOTES:103,
    SUBMITQUERY:104,
}