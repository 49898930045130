import { useCallback, useEffect } from 'react';
import { useDispatch } from 'src/redux/store';
import i18n from 'i18next';
import backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
// utils
import { localStorageGetItem } from 'src/utils/storage-available';
import { getLanguage } from 'src/redux/slices/localization/language';
//
import { defaultLang } from './config-lang';
//
import translationEn from './langs/en.json';
import translationFr from './langs/fr.json';
import translationVi from './langs/vi.json';
import translationCn from './langs/cn.json';
import translationAr from './langs/ar.json';

// ----------------------------------------------------------------------

// const lng = localStorageGetItem('i18nextLng', defaultLang.value);

// const backendOptions = ()=> {
//     try {
//       getLanguage().then((response:any) => {
//         const resources:any={};
//         response.data?.data[1]?.forEach((element:any) => {
//           resources[element.Name]=element.Value;
//         });
//         i18n
//           .use(LanguageDetector)
//           .use(initReactI18next)
//           .init({
//             resources: {
//               en: { translations: {...resources} }
//             },
//             lng,
//             fallbackLng: lng,
//             debug: false,
//             ns: ['translations'],
//             defaultNS: 'translations',
//             interpolation: {
//               escapeValue: false,
//             },
//           }); 
//       });
//     } catch (e) {
//       console.error(e);
//     }
//   }
//   backendOptions();

// i18n
//   .use(LanguageDetector)
//   .use(backend)
//   .init({
//     backend: backendOptions,
//     fallbackLng: "en",
//     debug: false,
//     load:"languageOnly",
//     ns: ["translations"],
//     defaultNS: "translations",
//     keySeparator: false, 
//     interpolation: {
//       escapeValue: false, 
//       formatSeparator: ","
//     }
// });

// i18n
//   .use(LanguageDetector)
//   .use(initReactI18next)
//   .init({
//     resources: {
//       en: { translations: translationEn },
//       fr: { translations: translationFr },
//       vi: { translations: translationVi },
//       cn: { translations: translationCn },
//       ar: { translations: translationAr },
//     },
//     lng,
//     fallbackLng: lng,
//     debug: false,
//     ns: ['translations'],
//     defaultNS: 'translations',
//     interpolation: {
//       escapeValue: false,
//     },
//   });

const resources: any = { en: { translations:{}}, de: {} } as any
      getLanguage().then((response:any) => {
        const resource:any={};
        response.data?.data[1]?.forEach((element:any) => {
          resource[element.Name]=element.Value;
        });
        resources.en.translations=resource;
        i18n.init(resources);
      });

export const availableLanguages = Object.keys(resources)

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        debug: false,
        ns: ['translations'],
        defaultNS: "translations",
        interpolation: {
         escapeValue: false,
        },
    });
  
export default i18n; 