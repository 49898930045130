import { Helmet } from 'react-helmet-async';
import DealerClassicLayout from 'src/layouts/auth/dealer';
// sections
import JwtClientForgotPasswordView from 'src/sections/auth/jwt/client/jwt-client-forgot-password-view';

// ----------------------------------------------------------------------

export default function ClientForgotPasswordPage() {
  return (
    <>
      <DealerClassicLayout>
        <>
          <Helmet>
            <title> Forgot Password</title>
          </Helmet>
          <JwtClientForgotPasswordView />
        </>
      </DealerClassicLayout>
    </>
  );
}
