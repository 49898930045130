import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// routes
import { RouterLink } from 'src/routes/components';
import { useSearchParams } from 'src/routes/hook';
import { paths } from 'src/routes/paths';
// config
// auth
import { useAuthContext } from 'src/auth/hooks';
// components
import DynamicFormNewPageById from 'src/sections/masters/settings/dynamic-form/dynamic-form-new-page-by-id';
import { CONSTANTDEFAULTPAGES } from 'src/utils/contants/config-utils';
import { useLocales } from 'src/locales';
import { newFormGetFormBlockSetupTableId } from 'src/utils/dynamic.form.generation.common.code';
import DynamicForByPageId from 'src/sections/masters/settings/dynamic-form/render-blocks/dynamic-form-by-page-id';
import { insertUpdateFormDetail } from 'src/redux/slices/settings/table-configuration';

// ----------------------------------------------------------------------

type FormValuesProps = {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
};

export default function JwtClientRegisterView() {
  const { register } = useAuthContext();
  const { t } = useLocales();
  const [errorMsg, setErrorMsg] = useState('');
  const [registrationStatus, setRegistrationStatus] = useState(false);
  const [pageId, setPageId] = useState(CONSTANTDEFAULTPAGES.CLIENTREGISTRATIONPAGE);
  const [selectedRow, setSelectedRow] = useState({} as any);
  const [watchFields, setWatchFields] = useState([] as any);

  const searchParams = useSearchParams();

  const returnTo = searchParams.get('returnTo');

  useEffect(() => {
    newFormGetFormBlockSetupTableId(0, pageId, watchFields, setWatchFields, setSelectedRow);
  }, [])

  const defaultValues = useMemo(() => ({
    ...selectedRow
  }), [selectedRow]);


  const methods = useForm<FormValuesProps>({
  });

  const {
    formState: { isSubmitting },
  } = methods;

  const onSubmitHandler = useCallback(
    (data: any) => {
      const obj: any = JSON.parse(JSON.stringify({ ...data, status: 0 }));
      delete obj.ddlData;
      delete obj.formBlockList;
      delete obj.watchFields;
      console.log(obj);
      insertUpdateFormDetail({ pageId, id: selectedRow.Id, jsonData: JSON.stringify(obj) }).then((res: any) => {
        if (res.data.data > 0 && res.data.statusCode === 200) {
          setRegistrationStatus(true);
        } else {
          setErrorMsg("Error please try again.");
          setRegistrationStatus(false);
        }
      })
    }, []);

  const renderHead = (
    <Stack spacing={2} sx={{ mb: 2, position: 'relative' }}>
      <Typography variant="h4">{t("LOGINFORMTITLE")}</Typography>
      <Stack direction="row" spacing={0.5}>
        <Typography variant="body2"> {t('AREADY_HAVE_ACCOUNT')} </Typography>
        <Link href={paths.auth.jwt.clientLogin} component={RouterLink} variant="subtitle2">
          {t('SIGNIN')}
        </Link>
      </Stack>

    </Stack>
  );

  const renderTerms = (
    <Typography
      component="div"
      sx={{ color: 'text.secondary', mt: 2.5, typography: 'caption', textAlign: 'center' }}
    >
      {t('SIGNUP_IAGREE')}
      <Link underline="always" color="text.primary">
        {t('TERMS_SERVICE')}
      </Link>
      &nbsp;{t('AND')} &nbsp;
      <Link underline="always" color="text.primary">
        {t('PRIVACY_POLICY')}
      </Link>
      .
    </Typography>
  );

  const renderForm = (
    <Stack spacing={2.5}>
      {!!errorMsg && <Alert severity="error">{errorMsg}</Alert>}
      {!registrationStatus && selectedRow && selectedRow?.formBlockList && <DynamicForByPageId key={crypto.randomUUID()} pageId={pageId}
        currentModel={defaultValues} onSubmitHandler={onSubmitHandler} children={<LoadingButton fullWidth color="inherit" size="large" type="submit" variant="contained" loading={isSubmitting}>
          {t("CREATE_ACCOUNT")}
        </LoadingButton>} />}
      {registrationStatus && <>
        <Alert severity="success">{t("REGISTRATION_SUCCESS")}</Alert>
        <Alert severity="success"><div dangerouslySetInnerHTML={{ __html: t("REGISTRATION_SUCCESS_CONTENT") as any }} /></Alert>
      </>}
    </Stack>
  );

  return (
    <>
      {renderHead}
      <Typography variant="h4" style={{ fontSize: '15px' }}>{t("SIGNUP")}</Typography>
      {renderForm}

      {renderTerms}
    </>
  );
}
