import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import calendarReducer from './slices/calendar';
import countryReducer from './slices/geography-settings/country';
import stateReducer from './slices/geography-settings/state';
import districtReducer from './slices/geography-settings/district';
import cityReducer from './slices/geography-settings/city';
import mandalReducer from './slices/geography-settings/mandal';
import pincodeReducer from './slices/geography-settings/pincode';
import ownerReducer from './slices/vehicle-settings/owner';
import vehicleHistoryReducer from './slices/vehicle-settings/vehicle.history';
import vehicleStatusReducer from './slices/vehicle-settings/vehicle.status';
import languageReducer from './slices/localization/language';
import localeStringResourceReducer from './slices/localization/locale-string-resource';
import columnDetailReducer from './slices/settings/table-configuration';
import formSetupReducer from './slices/settings/form-setup';
import formBlockSetupReducer from './slices/settings/form-block-setup';

// ----------------------------------------------------------------------

export const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['checkout'],
};

export const rootReducer = combineReducers({
  localeStringResource: localeStringResourceReducer,
  language: languageReducer,
  vehicleStatus: vehicleStatusReducer,
  vehicleHistory: vehicleHistoryReducer,
  owner: ownerReducer,
  columnDetail: columnDetailReducer,
  formSetup: formSetupReducer,
  formBlockSetup:formBlockSetupReducer,
  country: countryReducer,
  states: stateReducer,
  district: districtReducer,
  city: cityReducer,
  mandal: mandalReducer,
  pincode: pincodeReducer,
  calendar: calendarReducer,
});
