import { useLocation, matchPath } from 'react-router-dom';
import { useAuthContext } from 'src/auth/hooks';
import { paths } from '../paths';

// ----------------------------------------------------------------------

type ReturnType = boolean;

export function useActiveLink(path: string, deep = true): ReturnType {
  const { user } = useAuthContext();
  const { pathname } = useLocation();
  const clientName = user?.clientName?.replace(/ /g, '-')?.toLowerCase();
  let normalActive:any;
  let deepActive:any;
  if(clientName) {
    normalActive = path ? !!matchPath({path:`client/${user?.clientName?.replace(/ /g, '-')?.toLowerCase()}/${user?.role?.replace(/ /g, '-')?.toLowerCase()}/${path}` , end: true }, pathname) : false;
   deepActive = path ? !!matchPath({ path:`client/${user?.clientName?.replace(/ /g, '-')?.toLowerCase()}/${user?.role?.replace(/ /g, '-')?.toLowerCase()}/${path}`, end: false }, pathname) : false;
  }else {
      normalActive = path ? !!matchPath({path:`/${user?.role?.replace(/ /g, '-')?.toLowerCase()}/${path}` , end: true }, pathname) : false;
      deepActive = path ? !!matchPath({ path:`/${user?.role?.replace(/ /g, '-')?.toLowerCase()}/${path}`, end: false }, pathname) : false;
  }

  return deep ? deepActive : normalActive;
}
