import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { IVehicleHistoryState } from 'src/types/vehicle-settings/vehicle.history';
// utils
import axios, { API_ENDPOINTS } from 'src/utils/axios';

const initialState: IVehicleHistoryState = {
    vehicleHistory: [],
    vehicleHistoryStatus: {
        loading: false,
        empty: false,
        error: null
    },
    vehicleHistoryDelete: {
        loading: false,
        empty: false,
        error: null
    }
}

const slice = createSlice({
    name: 'vehicleHistory',
    initialState,
    reducers: {
        getVehicleHistoryStart(state) {
            state.vehicleHistoryStatus.loading = true;
            state.vehicleHistoryStatus.empty = false;
            state.vehicleHistoryStatus.error = null;
        },
        getVehicleHistoryFailure(state, action) {
            state.vehicleHistoryStatus.loading = true;
            state.vehicleHistoryStatus.empty = false;
            state.vehicleHistoryStatus.error = action.payload;
        },
        getVehicleHistorySuccess(state, action) {
            const vehicleHistory = action.payload;
            state.vehicleHistory = vehicleHistory;
            state.vehicleHistoryStatus.loading = false;
            state.vehicleHistoryStatus.empty = !vehicleHistory.length;
            state.vehicleHistoryStatus.error = null;
        },
        deleteVehicleHistoryStart(state) {
            state.vehicleHistoryDelete.loading = true;
            state.vehicleHistoryDelete.empty = false;
            state.vehicleHistoryDelete.error = null;
        },
        deleteVehicleHistoryFailure(state, action) {
            state.vehicleHistoryDelete.loading = true;
            state.vehicleHistoryDelete.empty = false;
            state.vehicleHistoryDelete.error = action.payload;
        },
        deleteVehicleHistorySuccess(state, action) {
            const vehicleHistoryDelete = action.payload;
            state.vehicleHistoryDelete = vehicleHistoryDelete;
            state.vehicleHistoryDelete.loading = false;
            state.vehicleHistoryDelete.empty = action.payload;
            state.vehicleHistoryDelete.error = null;
        }
    }
});

export default slice.reducer;

// Actions
export const {
    getVehicleHistoryStart,
    getVehicleHistoryFailure,
    getVehicleHistorySuccess,
    deleteVehicleHistoryStart,
    deleteVehicleHistoryFailure,
    deleteVehicleHistorySuccess
} = slice.actions;
 
export function getVehicleHistory(data:any) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.getVehicleHistoryStart());
        try {
            await axios.get(API_ENDPOINTS.VEHICLESETTINGS.VEHICLEHISTORY.LIST).then((res:any)=> {
                if(res.data.statusCode === 200)
                dispatch(slice.actions.getVehicleHistorySuccess(res.data.data));
            else 
                 dispatch(slice.actions.getVehicleHistoryFailure([]));
            });
        }catch(error){
            dispatch(slice.actions.getVehicleHistoryFailure(error));
        }
    }
}

export function deleteVehicleHistory(id: any) {
return  axios.delete(`${API_ENDPOINTS.VEHICLESETTINGS.VEHICLEHISTORY.DELETE}/${id}`);
}

export function addUpdateVehicleHistory(data: any) {
return axios.post(API_ENDPOINTS.VEHICLESETTINGS.VEHICLEHISTORY.ADDUPDATEVEHICLEHISTORY,data);
}